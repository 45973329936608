import React from 'react';


const CoachingHeader = props => {
    return (
        <nav className="Coaching-header">
            <ul className="Coaching-headerList">
                <li onClick={props.handleShowRoute("showLanding")}>Coaching</li>
                <li onClick={props.handleShowRoute("showServices")}>Services</li>
                <li onClick={props.handleShowRoute("showRates")}>Rates</li>
            </ul>
        </nav>
    )
}

export default CoachingHeader;