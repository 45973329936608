import React, { Component } from "react"
import MainLayout from "../components/layout/mainLayout"
import CoachingHeader from "../components/coaching/coachingHeader"
import CoachingLanding from "../components/coaching/coaching_landing"
import CoachingRates from "../components/coaching/coaching_rates"
import CoachingServices from "../components/coaching/coaching_services"

class Coaching extends Component {
  state = {
    showLanding: true,
    showRates: false,
    showServices: false
  }

  handleShowRoute = route => () => {
    Object.keys(this.state).forEach(page => {
      if(page == route) {
        this.setState({[page]: true});
        return;
      }
      this.setState({[page]: false});
    })
  }

  render() {
    return (
      <MainLayout>
        <main className="Coaching">
          <CoachingHeader handleShowRoute={this.handleShowRoute}/>
          <section className="Coaching-wrapper">
            { this.state.showLanding && <CoachingLanding />}
            { this.state.showRates && <CoachingRates />}
            { this.state.showServices && <CoachingServices />}
          </section>
        </main>
      </MainLayout>
    )
  }
}

export default Coaching