import React from "react"

const CoachingServices = props => {
  return (
    <section className="Coaching-services">
      <article className="Coaching-servicesWrapper">
        <div className="Coaching-servicesContent">
          <h3 className="Coaching-servicesContent--subHead">For actors: </h3>
          <p>
            Monologue scoring and monologue audition coaching, preparing sides
            for an audition, how to pick and effectively cut a monologue,
            heightened or period text analysis, creating a consistent
            physicality for a character, audition strategy
          </p>
        </div>
        <div className="Coaching-servicesContent">
          <h3 className="Coaching-servicesContent--subHead">
            For singing actors:
          </h3>
          <p>
            Dramatic coaching for arias and scenes - how to effectively
            communicate through song and develop a stronger stage presence and
            stage craft, mastering physicality in aria auditions, how to
            find/prepare a monologue and monologue audition coaching, preparing
            opera or MT dialogue, audition strategy, biography writing
          </p>
        </div>
        <div className="Coaching-servicesContent">
          <h3 className="Coaching-servicesContent--subHead">Bio Writing</h3>
          <p>
            Do you hate writing your own bio? Using just your resume and press
            quotes, I can craft a unique bio for you that actually tells a story
            and reads well.
          </p>
          <p>
            <span className="Coaching--bold">Full Package: </span>~500 word
            personal website bio, ~300 word “medium” bio for when you are
            featured on others’ websites, and 99 word micro bio for programs,
            total $125
          </p>
          <p>
            <span className="Coaching--bold">Medium length only:</span> $75
          </p>
        </div>
      </article>
      <article className="Coaching-servicesWrapper">
      <div className="Coaching-servicesContent">
        <h3 className="Coaching-servicesContent--subHead">
          Group Class: A Practical Introduction to the Craft of Acting
        </h3>
        <p>
          I have designed and successfully taught a 3-week intensive course for
          a group of 6-10 opera singers who want to dig into the craft of acting
          without the music. Many opera singers come to opera performance
          through a rigorous engagement with their vocal instrument and are
          often expected to know how to be a compelling actor with little
          training.{" "}
        </p>
        <br />
        <p>
          In this short intensive class, you will get a start on developing your
          acting craft and process through hands-on exercises that cultivate
          imagination and physicality, culminating in focused scene and
          monologue work from plays. Our priorities will be developing a method
          for scene analysis, listening and responding, taking risks, trying new
          things, and, yes, having fun. Whether you would like to deepen your
          craft as a singing actor, or whether you are interested in breaking
          into crossover work, this class will open up new possibilities for
          you.
        </p>
        <br />
        <p>
          Watch for details on the next round of this class in summer 2020.
        </p>
      </div>
      </article>
    </section>
  )
}

export default CoachingServices
