import React from "react"

const CoachingLanding = props => {
  return (
    <section className="Coaching-landing">
      <div className="Coaching-landingImage" />
      <article className="Coaching-landingWrapper">
        <p className="Coaching-landingContent">
          As a coach, I have helped actors and singers at all levels develop a
          practical and personal approach to performing and auditioning. Whether
          it is a side from a play, bel canto aria, Shakespeare monologue, new
          music art song, or something in between, I give you the tools and
          perspective you need to bring your dramatic craft to the next level.
        </p>
        <br />
        <p className="Coaching-landingQuote">
          I've been lucky to work with Adrienne as an actor in production and as
          a student in her scene study class. As a coach Adrienne works to
          empower actors while articulating high expectations. The course was
          only 3 weeks long, but I left with a semester's worth of materials and
          tools, and Adrienne helped me to discover choices that made me more
          active and authentic in my stage work. I would highly recommend this
          class.
          <br />
          <span className="Coaching-landingQuote--author">
            -{" "}
            <a
              href="https://www.kathrynmckellar.com/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              Kathryn McKellar
            </a>{" "}
            <br />
            <small>
              The Boston Conservatory at Berklee, Boston Lyric Opera, Lyric
              Stage Company of Boston, Odyssey Opera
            </small>
          </span>
        </p>
      </article>
    </section>
  )
}

export default CoachingLanding
