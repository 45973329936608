import React from 'react';

const CoachingRates = props => {
    return (
        <section className="Coaching-rates">
          <div className="Coaching-ratesImage" />
          <div className="Coaching-ratesContentWrapper">
            <article className="Coaching-ratesContent">
            <p className="Coaching-ratesContent--subHead">Private/Semi-private Coaching</p>
            <p>
              1-on-1 monologue, dialogue, physicality, song, or aria coaching:
              $70/hour
              <br />
              Small group scene study (you bring 1-3 friends): $50/person for 2
              people, $40 for 3-4, with a 2-hour minimum. I will custom choose a
              scene for you for $25 total extra
            </p>
            </article>
            </div>
        </section>
    );
}

export default CoachingRates;